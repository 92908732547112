import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn";
import CardComponent from "../components/cards/AdvisorsProfile";

import ManishImage from "../images/Team/Manish Paliwal.jpg";
import HarshvardhanImage from "../images/Team/CA Harshavardhan.jpg";
import AshokImage from "../images/Team/Ashok.jpg";

// // CSS styling to center the cards
// In your main file, remove the grid from CardsContainer since we're handling it in the CardComponent
const PageWrapper = tw.div`m-10`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
// Change this line - remove the grid since it's now in the CardComponent
const CardsContainer = tw.div`w-full`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center mt-5`;
const cards = [
  {
    imageSrc: ManishImage,
    position: "Founder - Legaltick",
    name: "Sh. Manish Paliwal, FCIArb",
    smallDescription: `
      Advocate on Record in the Supreme Court of India<br />
      Insolvency Professional & a Fellow of the Chartered Institute of Arbitrators<br />
      Attorney-at- Intellectual Property Law
    `,
    bigDescription: `
    <ul>
      <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Sh. Manish Paliwal</h1>
      <li>Extensive experience representing clients across the Indian judiciary - spanning the Supreme Court of India, the Delhi High Court, the National Company Law Tribunal (NCLT), and the National Company Law Appellate Tribunal (NCLAT)</li>
      <li>Expertise covers a wide range of legal areas, including:</li>
      <li><b>Corporate Law:</b> Advisory and representation on mergers, acquisitions, and governance.</li>
      <li><b>Bankruptcy & Insolvency:</b> Guidance through complex restructuring and resolution processes.</li>
      <li><b>Intellectual Property & Cyber Law:</b> Protection and enforcement of IP rights and digital compliance. Represented clients in patent opposition and litigation cases, providing thorough infringement analysis and strategic legal guidance.</li>
      <li><b>Administrative Law & Government Contracts:</b> Regulatory compliance and public sector contracting.</li>

    </ul>
  `,
  },
  {
    imageSrc: HarshvardhanImage,
    position: "Proprietor of Harshvardhan Pisal and Associates",
    name: "CA Harshvardhan Pisal",
    smallDescription: `
      Chartered Accountant<br />
      Extensive experience of providing service across industry specifically assisting to the Startups.
    `,
    bigDescription: `
    <ul>
      <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">CA Harshvardhan Pisal</h1>
      <li><b>Audit and Assurance:</b> Comprehensive auditing services to ensure financial accuracy and compliance</li>
      <li><b>Valuations:</b> Service related to financial valuations.</li>
      <li><b>Direct Taxation Services:</b> Expert guidance on tax planning, compliance, and optimization to minimize liabilities.</li>
      <li><b>Indirect Taxation Services:</b> Expert guidance in GST compliance and other indirect taxes</li>
      <li><b>Accounting and Book keeping:</b> Precise and timely bookkeeping services to maintain accurate financial records.</li>
      <li><b>Business Setup Consultancy:</b> Expertise in pre-incorporation, business set-up consultancy services</li>
    </ul>
  `,
  },
  {
    imageSrc: AshokImage,
    position: "Founder - IPBank",
    name: "Sh. Ashok Doppalapudi",
    smallDescription: `
      Indian Patent Agent & Indian Trademark Agent<br />
      BTech Mechanical Enginerring<br />
      LLB, PG in IP Law
    `,
    bigDescription: `
    <ul>
      <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Sh. Ashok Doppalapudi</h1>
      <li>Senior IP Counsel - <b>Ola Electric</b></li>
      <li>IP Manager - <b>Mahindra and Mahindra Limited</b></li>
      <li>Patent Engineer - <b>Ford Motor Company</b></li>
      <li>Project Support Engineer - <b>Patents-Tata Motors</b></li>
      <li>Knowledge Scientist - <b>Dolcera</b></li>

    </ul>
  `,
  },
  // Add more advisor cards here if needed
];

const AssociatesSection = () => (
  <CardsContainer>
    <CardComponent cards={cards} heading="" description="" />
  </CardsContainer>
);

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>Our Associates</SectionHeading>
      <PageWrapper>
        <Subheading></Subheading>
        <AssociatesSection />
      </PageWrapper>
      <Footer />
    </AnimationRevealPage>
  );
};

// export default AdvisorsSection;
