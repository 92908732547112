import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn";
import CardComponent from "../components/cards/AdvisorsProfile";
// import TeamCard from "../components/cards/ProfileAndDescription";
import SathyamurthyImage from "../images/Team/Sathyamurthy.jpg";
import NamitaImage from "../images/Team/Namita Maam.jpg";
import TataNarasingaImage from "../images/Team/Tata Narasinga Rao.jpg";
import RameshDharmajiImage from "../images/Team/dharmaji.jpg";
import SanjeevKumarVarshneyImage from "../images/Team/S K Varshney.jpg";
import ManishImage from "../images/Team/Manish Paliwal.jpg";
import PrakalpaSharmaImage from "../images/Team/Prakalpa Sharma.jpg";
import { ReactComponent as TwitterIcon } from "../images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "../images/linkedin-icon.svg";

// // CSS styling to center the cards
// In your main file, remove the grid from CardsContainer since we're handling it in the CardComponent
const PageWrapper = tw.div`m-10`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
// Change this line - remove the grid since it's now in the CardComponent
const CardsContainer = tw.div`w-full`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center mt-5`;
const cards = [
  {
    imageSrc: SathyamurthyImage,
    position: "Hon. Scientific Advisor",
    name: "Prof. N. Sathyamurthy",
    smallDescription: `
      Founder Director, Indian Institute of Science Education and Research (IISER) Mohali<br />
      Professor (Retired), IIT Kanpur
    `,
    bigDescription: `
    <ul>
    <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Prof. N. Sathyamurthy</h1>
        <li>Ex-Honorary Director Centre for Co-operation in Science & Technology among Developing Societies (CCSTDS) Chennai</li>
        <Indian>Fellow, Indian Academy of Sciences, Bangalore, Indian National Science Academy, New Delhi and Third World Academy of Sciences, Trieste, Italy</li>
        <li>Honorary Professor, JNCASR, Bangalore</li>
        <li>Honorary Professor, S. N. Bose National Center for Basic Sciences, Calcutta 1995-98</li>
        <li>S.S. Bhatnagar Prize in Chemical Sciences, CSIR</li>
        <li>Sir C.V. Raman Award, Hari Om Ashram Trust, University Grants Commission.</li>
        <li>J. C. Bose Fellow, Department of Science and Technology</li>
        <li>Editorial Board member of reputed National and International Journals</li>
        <li>225+ Scientific Research Publications</li>

      </ul>
    `,
  },
  {
    imageSrc: NamitaImage,
    position: "Hon. Scientific  Advisor",
    name: "Mrs Namita Gupta",
    smallDescription: `
      Ex-Scientist-G and Head, Innovation in Science Pursuit for Inspired Research (INSPIRE), DST<br />
      Ex-Head, INSPIRE-MANAK Program, DST<br />
      Ex- Advisor, KIRAN IPR scheme of DST
    `,
    bigDescription: `
    <ul>
    <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Mrs Namita Gupta</h1>
        <li>34+ years of experience in the Department of Science and Technology (DST).</li>
        <li>Expertise in S&T Planning and Policy Studies.</li>
        <li>Specialized in Science and Technology Indicators and R&D Resource Quantification.</li>
        <li>Committed to promoting women in science</li>
        <li>Advocated for youth empowerment through fellowships and scholarships.</li>
        <li>Passionate about nurturing critical thinking and innovation among school children.</li>
      </ul>
    `,
  },
  {
    imageSrc: TataNarasingaImage,
    position: "Hon. Scientific Advisor",
    name: "Prof. Tata Narasinga Rao",
    smallDescription: `
      Research Advisor & Adjunct Professor at Indian Institute of Technology Hyderabad<br />
      Ex- Director, ARCI-Hyderabad
    `,
    bigDescription: `
    <ul>
    <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Prof. Tata Narasinga Rao</h1>
        <li>Material Research Society of India (MRSI) Medal-2009</li>
        <li>Tokyo University of Science President Award-2014</li>
        <li>Academician of Asia Pacific Academy of Materials (APAM)-2015</li>
        <li>Technology Day National Award-2016</li>
        <li>Fellow of Telangana & AP Academy of Sciences-2017</li>
        <li>Bangalore India Nano Innovation Award-2018</li>
        <li>Materials Science Annual Prize-2022 of MRSI</li>
        <li>200+ Scientific Publications in National/International Journals</li>
        <li>Filed/granted 20 International and Indian patents, several of which have been translated to technological developments</li>
      </ul>
    `,
  },
  {
    imageSrc: RameshDharmajiImage,
    position: "Hon. Financial Advisor",
    name: "Sh. Ramesh Dharmaji",
    smallDescription: `
      Ex-CGM SIDBI<br />
      Founder Director of SIDBI International MSME Training Institute
    `,
    bigDescription: `
    <ul>
    <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Sh. Ramesh Dharmaji</h1>
        <li>A career Banker with over 38 years of experience in Promotion, Development and Financing of MSMEs, Product structuring, and delivery</li>
        <li>Sr. Adviser to Global Alliance for Mass Entrepreneurship</li>
        <li>Financial Expert for Technology Development Board</li>
        <li>Advisor to a venture capital firm</li>
        <li>Mentoring MSMEs in finance, strategy, etc.</li>
        <li>Mentored several startups</li>
      </ul>
    `,
  },
  {
    imageSrc: SanjeevKumarVarshneyImage,
    position: "Hon. Scientific Advisor for International Cooperation",
    name: "Dr. Sanjeev Kumar Varshney",
    smallDescription: `
      Science facilitator and promoter<br />
      Ex-Scientist G & Head, International S&T Cooperation - Department of Science and Technology
    `,
    bigDescription: `
    <ul>
    <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Dr. Sanjeev Kumar Varshney</h1>
        <li>A Science promoter, facilitator & Science communicator</li>
        <li>Facilitated International Scientific cooperation for more than 33 years from the Department of Science & Technology (DST), Government of India</li>
        <li>Editor of PINSA, Member - Academic Council HS Gaur University, Research, and Innovation Council, JSS Science & Technology University</li>
        <li>Counsellor (Science and Technology), Embassy of India in Moscow</li>
      </ul>
    `,
  },
  // {
  //   imageSrc: ManishImage,
  //   position: "Legal Advisor, Founder-Legaltick",
  //   name: "Sh. Manish Paliwal, FCIArb",
  //   smallDescription: `
  //     Advocate on Record in the Supreme Court of India<br />
  //     Insolvency Professional & a Fellow of the Chartered Institute of Arbitrators<br />
  //     Attorney-at- Intellectual Property Law
  //   `,
  //   bigDescription: `
  //   <ul>
  //     <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Sh. Manish Paliwal</h1>
  //     <li>Extensive experience representing clients across the Indian judiciary - spanning the Supreme Court of India, the Delhi High Court, the National Company Law Tribunal (NCLT), and the National Company Law Appellate Tribunal (NCLAT)</li>
  //     <li>Expertise covers a wide range of legal areas, including:</li>
  //     <li><b>Corporate Law:</b> Advisory and representation on mergers, acquisitions, and governance.</li>
  //     <li><b>Bankruptcy & Insolvency:</b> Guidance through complex restructuring and resolution processes.</li>
  //     <li><b>Intellectual Property & Cyber Law:</b> Protection and enforcement of IP rights and digital compliance. Represented clients in patent opposition and litigation cases, providing thorough infringement analysis and strategic legal guidance.</li>
  //     <li><b>Administrative Law & Government Contracts:</b> Regulatory compliance and public sector contracting.</li>

  //   </ul>
  // `,
  // },
  // Add more advisor cards here if needed
];

const AdvisorsSection = () => (
  <CardsContainer>
    <CardComponent cards={cards} heading="" description="" />
  </CardsContainer>
);

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>Our Advisors</SectionHeading>
      <PageWrapper>
        <Subheading></Subheading>
        <AdvisorsSection />
      </PageWrapper>
      <Footer />
    </AnimationRevealPage>
  );
};

// export default AdvisorsSection;
