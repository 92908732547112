import React, { useState, useEffect } from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

import TeamMembersPage from "pages/TeamMembers.js";
import AdvisorsPage from "pages/Advisors.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsForm from "pages/ContactUs";
import PrivacyPolicy from "pages/PrivacyPolicy";
import BlogIndex from "pages/BlogIndex";
import BlogPage from "pages/Blogs";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SaaSProductLandingPage from "demos/SaaSProductLandingPage";
import ScrollToTop from "components/ScrollToTop";
import ModelQuestionPaper from "pages/ModelQuestionPaper";
import PaymentPage from "pages/PaymentPage";
import Partners from "pages/Partners";
import Associates from "pages/Associates";

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const disclaimerBoxStyle = {
  backgroundColor: "white",
  padding: "2rem",
  borderRadius: "8px",
  textAlign: "center",
  maxWidth: "500px",
  width: "80%",
};

const buttonStyle = {
  marginTop: "1rem",
  padding: "0.5rem 1rem",
  fontSize: "1rem",
  borderRadius: "4px",
  backgroundColor: "#4CAF50",
  color: "white",
  border: "none",
  cursor: "pointer",
};

export default function App() {
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    // Check localStorage for disclaimer acceptance status
    const isDisclaimerAccepted = localStorage.getItem("disclaimerAccepted");
    if (!isDisclaimerAccepted) {
      // If not accepted, show disclaimer after 1 second
      const timer = setTimeout(() => {
        setShowDisclaimer(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setDisclaimerAccepted(true);
    }
  }, []);

  const handleAcceptDisclaimer = () => {
    // Set the disclaimer as accepted in localStorage
    localStorage.setItem("disclaimerAccepted", "true");
    setDisclaimerAccepted(true);
    setShowDisclaimer(false);
  };

  return (
    <>
      <GlobalStyles />
      {showDisclaimer && !disclaimerAccepted && (
        <div style={overlayStyle}>
          <div style={disclaimerBoxStyle}>
            <h2>Disclaimer</h2>
            <p>
              By clicking on "I AGREE", you agree that your use of this website
              is limited to the purpose of obtaining information about the firm
              and its activities.
            </p>
            <button onClick={handleAcceptDisclaimer} style={buttonStyle}>
              I AGREE
            </button>
          </div>
        </div>
      )}
      {(disclaimerAccepted || !showDisclaimer) && (
        <Router>
          <ScrollToTop />

          <Routes>
            <Route path="/blogs/:id" element={<BlogPage />} />{" "}
            {/* Blog detail page */}
            <Route path="/team" element={<TeamMembersPage />} />
            <Route path="/advisors" element={<AdvisorsPage />} />
            <Route path="/associates" element={<Associates />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/contact-us" element={<ContactUsForm />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blogs" element={<BlogIndex />} />
            <Route path="/partners" element={<Partners />} />
            {/* <Route path="/payment-page" element={<PaymentPage />} />
            <Route
              path="/model-question-paper"
              element={<ModelQuestionPaper />}
            /> */}
            <Route path="/" element={<SaaSProductLandingPage />} />
          </Routes>
        </Router>
      )}
    </>
  );
}
